import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PricingSectionNavigations2 from '../components/navigations/PricingSectionNavigations2';
import PricingSectionPricing1 from '../components/pricing/PricingSectionPricing1';
import FeaturesSectionNavigations2 from "../components/navigations/FeaturesSectionNavigations2";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Pricing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <FeaturesSectionNavigations2 />
      <PricingSectionPricing1 />
    </React.Fragment>
  );
}


import React from 'react'
import IndexSectionFooters10 from '../components/footers/IndexSectionFooters10'

const TOSPage = () => {
  return (
    <div>
      <main className="container px-4 mx-auto">
        <div className="relative flex items-center justify-between py-5">
          <div className="w-auto">
            <a
              className="inline-block"
              href="/"
            >
              <img
                src="https://assets-global.website-files.com/6481ec2ea815e9af86170e86/6481ec2ea815e9af86170ef1_rodeo%20-%20logo.svg"
                alt
                className="h-10"
              />
            </a>
          </div>
          {/*<div className="w-auto hidden lg:block">*/}
          {/*    <ul className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center">*/}
          {/*        <li className="mr-12"><a href="/features" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Features</a></li>*/}
          {/*        <li className="mr-12"><a href="/pricing" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Pricing</a></li>*/}
          {/*        <li className="mr-12"><a href="/about" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">About</a></li>*/}
          {/*        <li><a href="/blog" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Blog</a></li>*/}
          {/*    </ul>*/}
          {/*</div>*/}
          <div className="w-auto hidden lg:block">
            <div className="flex flex-wrap items-center -m-2">
              <div className="auto p-2">
                <a
                  href="https://ask.hey.rodeo/intake-agency"
                  target="_blank"
                  className="group relative inline-flex justify-center text-center"
                >
                  <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">
                    Become A Partner (agencies)
                  </span>
                  <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200" />
                </a>
              </div>
              <div className="auto p-2">
                <a
                  href="https://ask.hey.rodeo/intake-brand"
                  target="_blank"
                  className="group relative inline-flex justify-center text-center"
                >
                  <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium text-white border border-lynch-900">
                    Request Early Access (brands)
                  </span>
                  <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
                </a>
              </div>
            </div>
          </div>
          <div className="w-auto lg:hidden">
            <a
              className="inline-block navbar-burger"
              href="#"
            >
              <svg
                width={51}
                height={51}
                viewBox="0 0 56 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className=" text-lynch-500"
              >
                <rect
                  width={56}
                  height={56}
                  rx={28}
                  fill="currentColor"
                />
                <path
                  d="M37 32H19M37 24H19"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>

        <h1
          style={{
            fontSize: '2rem',
            fontWeight: 600
          }}
        >
          Terms of Service
        </h1>
        <p>
          <br />
          These Terms of Service govern your use of the website located at https://hey.rodeo and any related services
          provided by Rodeo Commerce, Inc.
          <br />
          ‍<br />
          By accessing https://hey.rodeo, you agree to abide by these Terms of Service and to comply with all applicable
          laws and regulations. If you do not agree with these Terms of Service, you are prohibited from using or
          accessing this website or using any other services provided by Rodeo Commerce, Inc.
          <br />
          ‍<br />
          We, Rodeo Commerce, Inc, reserve the right to review and amend any of these Terms of Service at our sole
          discretion. Upon doing so, we will update this page. Any changes to these Terms of Service will take effect
          immediately from the date of publication.
          <br />
          ‍<br />
          These Terms of Service were last updated on 17 August 2022.
          <br />
          <br />
          Confidentiality
          <br />
          1. “Confidential Information” will include, but will not be limited to, any and all information associated
          with a party’s business and not publicly known, including specific business information, technical processes
          and formulas, software, customer lists, prospective customer lists, names, addresses and other information
          regarding customers and prospective customers, product designs, sales, costs (including any relevant
          processing fees), price lists, and other unpublished financial information, business plans and marketing data,
          and any other confidential and proprietary information, whether or not marked as confidential or proprietary.
          Rodeo Commerce, Inc’s Confidential Information includes all information that you receive relating to us, or to
          the Services, that is not known to the general public including information related to our security program
          and practices.
          <br />
          <br />
          2. Each party agrees to use the other party’s Confidential Information solely as necessary for performing its
          obligations under these Terms of Service and in accordance with any other obligations in these Terms of
          Service. Each party agrees that it will take all reasonable steps, at least substantially equivalent to the
          steps it takes to protect its own proprietary information, to prevent the duplication, disclosure or use of
          any such Confidential Information, other than (i) by or to its employees, agents and subcontractors who must
          have access to such Confidential Information to perform such party’s obligations hereunder, who each will
          treat such Confidential Information as provided herein, and who are each subject to obligations of
          confidentiality to such party that are at least as stringent as those contained herein; or (ii) as required by
          any law, regulation, or order of any court of proper jurisdiction over the parties and the subject matter
          contained in these Terms of Service, provided that, if legally permitted, the receiving party will give the
          disclosing party prompt written notice and use commercially reasonable efforts to ensure that such disclosure
          is accorded confidential treatment. Confidential Information will not include any information that the
          receiving party can prove: (A) was already in the public domain, or was already known by or in the possession
          of the receiving party, at the time of disclosure of such information; (B) is independently developed by the
          receiving party without use of or reference to the other party’s Confidential Information, and without
          breaching any provisions of these Terms of Service; or (C) is thereafter rightly obtained by the receiving
          party from a source other than the disclosing party without breaching any provision of these Terms of Service.
          <br />
          <br />
          Limitations of Use
          <br />
          By using this website, you warrant on behalf of yourself, your users, and other parties you represent that you
          will not:
          <br />
          ‍<br />
          - modify, copy, prepare derivative works of, decompile, or reverse engineer any materials and software
          contained on this website;
          <br />
          - remove any copyright or other proprietary notations from any materials and software on this website;
          <br />
          - transfer the materials to another person or “mirror” the materials on any other server;
          <br />
          - knowingly or negligently use this website or any of its associated services in a way that abuses or disrupts
          our networks or any other service Rodeo Commerce, Inc provides;
          <br />
          - use this website or its associated services to transmit or publish any harassing, indecent, obscene,
          fraudulent, or unlawful material;
          <br />
          - use this website or its associated services in violation of any applicable laws or regulations;
          <br />
          - use this website in conjunction with sending unauthorized advertising or spam;
          <br />
          - harvest, collect, or gather user data without the user’s consent; or
          <br />
          - use this website or its associated services in such a way that may infringe the privacy, intellectual
          property rights, or other rights of third parties.
          <br />
          <br />
          Intellectual Property
          <br />
          The intellectual property in the materials contained in this website are owned by or licensed to Rodeo
          Commerce, Inc and are protected by applicable copyright and trademark law. We grant our users permission to
          download one copy of the materials for personal, non-commercial transitory use.
          <br />
          ‍<br />
          This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate
          if you violate any of these restrictions or the Terms of Service, and may be terminated by Rodeo Commerce, Inc
          at any time.
          <br />
          <br />
          Liability
          <br />
          Our website and the materials on our website are provided on an 'as is' basis. To the extent permitted by law,
          Rodeo Commerce, Inc makes no warranties, expressed or implied, and hereby disclaims and negates all other
          warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a
          particular purpose, or non-infringement of intellectual property, or other violation of rights.
          <br />
          ‍<br />
          In no event shall Rodeo Commerce, Inc or its suppliers be liable for any consequential loss suffered or
          incurred by you or any third party arising from the use or inability to use this website or the materials on
          this website, even if Rodeo Commerce, Inc or an authorized representative has been notified, orally or in
          writing, of the possibility of such damage.
          <br />
          ‍<br />
          In the context of this agreement, “consequential loss” includes any consequential loss, indirect loss, real or
          anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of
          opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under
          statute, contract, equity, tort (including negligence), indemnity, or otherwise.
          <br />
          ‍<br />
          Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for
          consequential or incidental damages, these limitations may not apply to you.
          <br />
          <br />
          Accuracy of Materials
          <br />
          The materials appearing on our website are not comprehensive and are for general information purposes only.
          Rodeo Commerce, Inc does not warrant or make any representations concerning the accuracy, likely results, or
          reliability of the use of the materials on this website, or otherwise relating to such materials or on any
          resources linked to this website.
          <br />
          <br />
          Links
          <br />
          Rodeo Commerce, Inc has not reviewed all of the sites linked to its website and is not responsible for the
          contents of any such linked site. The inclusion of any link does not imply endorsement, approval, or control
          by Rodeo Commerce, Inc of the site. Use of any such linked site is at your own risk and we strongly advise you
          make your own investigations with respect to the suitability of those sites.
          <br />
          <br />
          Right to Terminate
          <br />
          We may suspend or terminate your right to use our website and terminate these Terms of Service immediately
          upon written notice to you for any breach of these Terms of Service.
          <br />
          <br />
          Severance
          <br />
          Any term of these Terms of Service which is wholly or partially void or unenforceable is severed to the extent
          that it is void or unenforceable. The validity of the remainder of these Terms of Service is not affected.
          <br />
          <br />
          Governing Law
          <br />
          These Terms of Service are governed by and construed in accordance with the laws of USA. You irrevocably
          submit to the exclusive jurisdiction of the courts in that State or location.
        </p>
      </main>

      <IndexSectionFooters10 />
    </div>
  )
}

export default TOSPage

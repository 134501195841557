import React from 'react';

export default function IndexSectionFeatures5() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden ">
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap -m-8">
                            <div className="w-full md:w-1/2 p-8">
                                <div className="md:max-w-lg mx-auto"><h3 className="font-heading mb-6 text-4xl font-semibold">Instantly identify &amp; log in shoppers from Email/SMS</h3>
                                    <div className="flex flex-wrap -m-2.5">
                                        <div className="w-full p-4">
                                            <div className="flex flex-wrap -m-1.5">
                                                <div className="w-auto p-1.5">
                                                    <div className="relative inline-flex">
                                                        <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                                                            <svg width={12} height={10} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M11.5917 1.00834C11.5142 0.930232 11.4221 0.868237 11.3205 0.82593C11.219 0.783622 11.11 0.761841 11 0.761841C10.89 0.761841 10.7811 0.783622 10.6796 0.82593C10.578 0.868237 10.4858 0.930232 10.4084 1.00834L4.20004 7.22501L1.59171 4.60834C1.51127 4.53064 1.41632 4.46955 1.31227 4.42854C1.20823 4.38754 1.09713 4.36743 0.985308 4.36937C0.873491 4.3713 0.76315 4.39524 0.660584 4.43982C0.558019 4.4844 0.465238 4.54874 0.387539 4.62917C0.309841 4.70961 0.248746 4.80456 0.207742 4.9086C0.166739 5.01265 0.14663 5.12375 0.148565 5.23557C0.150499 5.34739 0.174439 5.45773 0.219017 5.56029C0.263595 5.66286 0.327938 5.75564 0.408373 5.83334L3.60837 9.03334C3.68584 9.11145 3.77801 9.17344 3.87956 9.21575C3.98111 9.25806 4.09003 9.27984 4.20004 9.27984C4.31005 9.27984 4.41897 9.25806 4.52052 9.21575C4.62207 9.17344 4.71424 9.11145 4.79171 9.03334L11.5917 2.23334C11.6763 2.1553 11.7438 2.06059 11.79 1.95518C11.8361 1.84976 11.86 1.73592 11.86 1.62084C11.86 1.50575 11.8361 1.39192 11.79 1.2865C11.7438 1.18108 11.6763 1.08637 11.5917 1.00834Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                        </div>
                                                        <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200"/></div>
                                                </div>
                                                <div className="flex-1 p-1.5"><h6 className="font-heading mb-3 text-xl font-semibold">Other tools try to identify anonymous traffic with mixed results - that's NOT us.</h6>                        <p className="text-base text-gray-500 font-medium">We make life easier for your known users. When they return to your site via email or SMS links - we eliminate friction and ID 100% of visitors.</p></div>
                                            </div>
                                        </div>
                                        <div className="w-full p-4">
                                            <div className="flex flex-wrap -m-1.5">
                                                <div className="w-auto p-1.5">
                                                    <div className="relative inline-flex">
                                                        <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                                                            <svg width={12} height={10} viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M11.5917 1.00834C11.5142 0.930232 11.4221 0.868237 11.3205 0.82593C11.219 0.783622 11.11 0.761841 11 0.761841C10.89 0.761841 10.7811 0.783622 10.6796 0.82593C10.578 0.868237 10.4858 0.930232 10.4084 1.00834L4.20004 7.22501L1.59171 4.60834C1.51127 4.53064 1.41632 4.46955 1.31227 4.42854C1.20823 4.38754 1.09713 4.36743 0.985308 4.36937C0.873491 4.3713 0.76315 4.39524 0.660584 4.43982C0.558019 4.4844 0.465238 4.54874 0.387539 4.62917C0.309841 4.70961 0.248746 4.80456 0.207742 4.9086C0.166739 5.01265 0.14663 5.12375 0.148565 5.23557C0.150499 5.34739 0.174439 5.45773 0.219017 5.56029C0.263595 5.66286 0.327938 5.75564 0.408373 5.83334L3.60837 9.03334C3.68584 9.11145 3.77801 9.17344 3.87956 9.21575C3.98111 9.25806 4.09003 9.27984 4.20004 9.27984C4.31005 9.27984 4.41897 9.25806 4.52052 9.21575C4.62207 9.17344 4.71424 9.11145 4.79171 9.03334L11.5917 2.23334C11.6763 2.1553 11.7438 2.06059 11.79 1.95518C11.8361 1.84976 11.86 1.73592 11.86 1.62084C11.86 1.50575 11.8361 1.39192 11.79 1.2865C11.7438 1.18108 11.6763 1.08637 11.5917 1.00834Z"
                                                                    fill="currentColor"/>
                                                            </svg>
                                                        </div>
                                                        <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200"/></div>
                                                </div>
                                                <div className="flex-1 p-1.5"><h6 className="font-heading mb-3 text-xl font-semibold">Klaviyo knows "Jess" clicked, so should your store.</h6>                        <p className="text-base text-gray-500 font-medium">There's a massive disconnect in your funnel: 90% of known traffic from email/sms you sent, land on your store anonymous. We fix that and get everyone re-acquainted.&nbsp;</p></div>
                                            </div>
                                        </div>
                                        <div className="w-full p-4">
                                            <div className="flex flex-wrap -m-1.5">
                                                <div className="w-auto p-1.5">
                                                    <div className="relative inline-flex"><img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/1688213347434.jpeg" alt="James Booth" className="w-16 h-16 rounded-full object-cover"/>
                                                    </div>
                                                </div>
                                                <div className="flex-1 p-1.5"><p className="text-base text-gray-500 font-medium mb-2 italic" contentEditable="true">"Anyone that doesn't use this is setting money on fire."</p>                        <h6 className="font-heading text-lg font-semibold">James Booth</h6>                        <p className="text-sm text-gray-500">CMO at <span className="inline-block font-medium text-sm text-gray-700">Biom</span></p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 p-8">
                                <div className="max-w-xl mx-auto"><img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/shake-hands.png" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        </React.Fragment>
    );
}


import React from 'react'
import { Routes, Route } from 'react-router-dom'

import IndexPage from './pages/Index.js'
import FeaturesPage from './pages/Features.js'
import PricingPage from './pages/Pricing.js'
import AboutPage from './pages/About.js'
import BlogPage from './pages/Blog.js'
import PrivacyPolicyPage from './pages/PrivacyPolicy.js'
import TOSPage from './pages/TOS.js'

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={<IndexPage />}
      />

      <Route
        path="/features"
        element={<FeaturesPage />}
      />

      <Route
        path="/pricing"
        element={<PricingPage />}
      />

      <Route
        path="/about"
        element={<AboutPage />}
      />

      <Route
        path="/blog"
        element={<BlogPage />}
      />

      <Route
        path="/privacy-policy"
        element={<PrivacyPolicyPage />}
      />

      <Route
        path="/terms-of-service"
        element={<TOSPage />}
      />
    </Routes>
  )
}

export default App

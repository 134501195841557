import React from 'react'
import IndexSectionFooters10 from '../components/footers/IndexSectionFooters10';

const PrivacyPolicyPage = () => {
  return (
    <div>

      <main
       className="container px-4 mx-auto"
      >
        <div className="relative flex items-center justify-between py-5">
                            <div className="w-auto">
                                <a className="inline-block" href="/">
                                    <img src="https://assets-global.website-files.com/6481ec2ea815e9af86170e86/6481ec2ea815e9af86170ef1_rodeo%20-%20logo.svg" alt className="h-10"/></a>
                            </div>
                            {/*<div className="w-auto hidden lg:block">*/}
                            {/*    <ul className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center">*/}
                            {/*        <li className="mr-12"><a href="/features" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Features</a></li>*/}
                            {/*        <li className="mr-12"><a href="/pricing" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Pricing</a></li>*/}
                            {/*        <li className="mr-12"><a href="/about" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">About</a></li>*/}
                            {/*        <li><a href="/blog" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Blog</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <div className="w-auto hidden lg:block">
                                <div className="flex flex-wrap items-center -m-2">
                                    <div className="auto p-2">
                                        <a href="https://ask.hey.rodeo/intake-agency" target="_blank" className="group relative inline-flex justify-center text-center">
                                            <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">Become A Partner (agencies)</span>
                                            <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200"/>
                                        </a>
                                    </div>
                                    <div className="auto p-2">
                                        <a href="https://ask.hey.rodeo/intake-brand" target="_blank" className="group relative inline-flex justify-center text-center">
                                            <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium text-white border border-lynch-900">Request Early Access (brands)</span>
                                            <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-auto lg:hidden">
                                <a className="inline-block navbar-burger" href="#">
                                    <svg width={51} height={51} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className=" text-lynch-500">
                                        <rect width={56} height={56} rx={28} fill="currentColor"/>
                                        <path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
          <h1
          style={{
            fontSize: "2rem",
            fontWeight: 600,
            }}
          >Privacy Policy</h1>
<br />
  <p>Your privacy is important to us. It is Rodeo Commerce, Inc's policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <a href="https://hey.rodeo">https://hey.rodeo</a>, and other sites we own and operate.</p>
<br />

  <p>Personal information is any information about you which can be used to identify you. This includes information about you as a person (such as name, address, and date of birth), your devices, payment details, and even information about how you use a website or online service.</p>

<br />
  <p>In the event our site contains links to third-party sites and services, please be aware that those sites and services have their own privacy policies. After following a link to any third-party content, you should read their posted privacy policy information about how they collect and use personal information. This Privacy Policy does not apply to any of your activities after you leave our site.</p>
<br />

  <p>This policy is effective as of 17 August 2022.</p>
<br />

  <p><strong>Last updated: 17 August 2022</strong></p>

  <p>Information we collect falls into one of two categories: “voluntarily provided” information and “automatically collected” information.</p>
<br />
  <ul>
    <li><strong>1. Voluntarily provided</strong> information refers to any information you knowingly and actively provide us when using or participating in any of our services and promotions.</li>
    <li><strong>2. Automatically collected</strong> information refers to any information automatically sent by your devices in the course of accessing our products and services.</li>
  </ul>
<br />

  <h3><b>Log Data</b></h3>

  <p>When you visit our website, our servers may automatically log the standard data provided by your web browser. It may include your device’s Internet Protocol (IP) address, your browser type and version, the pages you visit, the time and date of your visit, the time spent on each page, and other details about your visit.</p>
<br />

  <p>Additionally, if you encounter certain errors while using the site, we may automatically collect data about the error and the circumstances surrounding its occurrence. This data may include technical details about your device, what you were trying to do when the error happened, and other technical information relating to the problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred, or what the nature of the error is.</p>
<br />

  <p>Please be aware that while this information may not be personally identifying by itself, it may be possible to combine it with other data to personally identify individual persons.</p>
<br />

  <h3><b>Device Data</b></h3>

  <p>When you visit our website or interact with our services, we may automatically collect data about your device, such as:</p>

  <ul>
    <li><b>1. </b> Device Type</li>
    <li><b>2. </b> Operating System</li>
  </ul>
  
  <br />

  <p>Data we collect can depend on the individual settings of your device and software. We recommend checking the policies of your device manufacturer or software provider to learn what information they make available to us.</p>
            <br />

  <h3><b>Personal Information</b></h3>

  <p>We may ask for personal information — for example, when you subscribe to our newsletter or when you contact us — which may include one or more of the following:</p>

  <ul>
    <li><b>1. </b> Name</li>
    <li><b>2. </b> Email</li>
    <li><b>3. </b> Phone/mobile number</li>
  </ul>
  
  <br />

  <h3><b>Transaction Data</b></h3>
  <p>Transaction data refers to data that accumulates over the normal course of operation on our platform. This may include transaction records, stored files, user profiles, analytics data, and other metrics, as well as other types of information, created or generated, as users interact with our services.</p>
  
  <br />

  <h2><b>Legitimate Reasons for Processing Your Personal Information</b></h2>

  <p>We only collect and use your personal information when we have a legitimate reason for doing so. In which instance, we only collect personal information that is reasonably necessary to provide our services to you.</p>
  <br />

  <h2><b>Collection and Use of Information</b></h2>

  <p>We may collect personal information from you when you do any of the following on our website:</p>

  <ul>
    <li><b>1. </b> Register for an account</li>
    <li><b>2. </b> Purchase a subscription</li>
    <li><b>3. </b> Sign up to receive updates from us via email or social media channels</li>
    <li><b>4. </b> Use a mobile device or web browser to access our content</li>
    <li><b>5. </b> Contact us via email, social media, or on any similar technologies</li>
    <li><b>6. </b> When you mention us on social media</li>
  </ul>

  <br />

  <p>We may collect, hold, use, and disclose information for the following purposes, and personal information will not be further processed in a manner that is incompatible with these purposes:</p>

  <ul>
    <li><b>1. </b> to provide you with our platform's core features and services</li>
    <li><b>2. </b> to deliver products and/or services to you</li>
    <li><b>3. </b> to contact and communicate with you</li>
    <li><b>4. </b> for analytics, market research, and business development, including to operate and improve our website, associated applications, and associated social media platforms</li>
    <li><b>5. </b> for technical assessment, including to operate and improve our app, associated applications, and associated social media platforms</li>
  </ul>

  <br />

  <p>We may combine voluntarily provided and automatically collected personal information with general information or research data we receive from other trusted sources. For example, If you provide us with your location, we may combine this with general information about currency and language to provide you with an enhanced experience of our site and service.</p>

  <br />

  <h2><b>Security of Your Personal Information</b></h2>

  <p>When we collect and process personal information, and while we retain this information, we will protect it within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use, or modification.</p>

  <br />

  <p>Although we will do our best to protect the personal information you provide to us, we advise that no method of electronic transmission or storage is 100% secure, and no one can guarantee absolute data security.</p>

  <br />
  <p>You are responsible for selecting any password and its overall security strength, ensuring the security of your own information within the bounds of our services. For example, ensuring any passwords associated with accessing your personal information and accounts are secure and confidential.</p>

  <br />
  <h2><b>How Long We Keep Your Personal Information</b></h2>

  <p>We keep your personal information only for as long as we need to. This time period may depend on what we are using your information for, in accordance with this privacy policy. For example, if you have provided us with personal information as part of creating an account with us, we may retain this information for the duration your account exists on our system. If your personal information is no longer required for this purpose, we will delete it or make it anonymous by removing all details that identify you.</p>
  <br />
  <p>However, if necessary, we may retain your personal information for our compliance with a legal, accounting, or reporting obligation or for archiving purposes in the public interest, scientific, or historical research purposes or statistical purposes.</p>
  
  
            <br />

  <h2><b>Children’s Privacy</b></h2>
  <p>We do not aim any of our products or services directly at children under the age of 13, and we do not knowingly collect personal information about children under 13.</p>
  
<br />

  <h2><b>Disclosure of Personal Information to Third Parties</b></h2>
  <p>We may disclose personal information to:</p>
  <ul>
    <li><b>1. </b> a parent, subsidiary, or affiliate of our company</li>
    <li><b>2. </b> third-party service providers for the purpose of enabling them to provide their services, including (without limitation) IT service providers, data storage, hosting and server providers, analytics, error loggers, debt collectors, maintenance or problem-solving providers, marketing providers, professional advisors, and payment systems operators</li>
    <li><b>3. </b> our employees, contractors, and/or related entities</li>
    <li><b>4. </b> our existing or potential agents or business partners</li>
    <li><b>5. </b> credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail to pay for goods or services we have provided to you</li>
    <li><b>6. </b> courts, tribunals, regulatory authorities, and law enforcement officers, as required by law, in connection with any actual or prospective legal proceedings, or in order to establish, exercise, or defend our legal rights</li>
    <li><b>7. </b> third parties, including agents or sub-contractors, who assist us in providing information, products, services, or direct marketing to you</li>
    <li><b>8. </b> third parties to collect and process data</li>
    <li><b>9. </b> an entity that buys, or to which we transfer all or substantially all of our assets and business</li>
  </ul>

<br />

  <h3><b>Third parties we currently use include:</b></h3>

<br />

  <h2><b>Your Rights and Controlling Your Personal Information</b></h2>
  <p><b>Your choice:</b> By providing personal information to us, you understand we will collect, hold, use, and disclose your personal information in accordance with this privacy policy. You do not have to provide personal information to us, however, if you do not, it may affect your use of our website or the products and/or services offered on or through it.</p>

<br />
  <p><b>Information from third parties:</b> If we receive personal information about you from a third party, we will protect it as set out in this privacy policy. If you are a third party providing personal information about somebody else, you represent and warrant that you have such person’s consent to provide the personal information to us.</p>

<br />
  <p><b>Marketing permission:</b> If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by contacting us using the details below.</p>
<br />

  <p><b>Access:</b> You may request details of the personal information that we hold about you.</p>
<br />

  <p><b>Correction:</b> If you believe that any information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, please contact us using the details provided in this privacy policy. We will take reasonable steps to correct any information found to be inaccurate, incomplete, misleading, or out of date.</p>
<br />

  <p><b>Non-discrimination:</b> We will not discriminate against you for exercising any of your rights over your personal information. Unless your personal information is required to provide you with a particular service or offer (for example processing transaction data), we will not deny you goods or services and/or charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties, or provide you with a different level or quality of goods or services.</p>
<br />

  <p><b>Downloading of Personal Information:</b> We provide a means for you to download the personal information you have shared through our site. Please contact us for more information.</p>
<br />

  <p><b>Notification of data breaches:</b> We will comply with laws applicable to us in respect of any data breach.</p>
<br />

  <p><b>Complaints:</b> If you believe that we have breached a relevant data protection law and wish to make a complaint, please contact us using the details below and provide us with full details of the alleged breach. We will promptly investigate your complaint and respond to you, in writing, setting out the outcome of our investigation and the steps we will take to deal with your complaint. You also have the right to contact a regulatory body or data protection authority in relation to your complaint.</p>
<br />

  <p><b>Unsubscribe:</b> To unsubscribe from our email database or opt-out of communications (including marketing communications), please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities provided in the communication. We may need to request specific information from you to help us confirm your identity.</p>
<br />

  <h2><b>Use of Cookies</b></h2>
  <p>We use “cookies” to collect information about you and your activity across our site. A cookie is a small piece of data that our website stores on your computer, and accesses each time you visit, so we can understand how you use our site. This helps us serve you content based on preferences you have specified.</p>

            <br />

  <p>Please refer to our Cookie Policy for more information.</p>
            <br />

  <h2><b>Business Transfers</b></h2>
  <p>If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy, we would include data, including your personal information, among the assets transferred to any parties who acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the extent permitted by applicable law, continue to use your personal information according to this policy, which they will be required to assume as it is the basis for any ownership or use rights we have over such information.</p>

<br />

  <h2><b>Limits of Our Policy</b></h2>
  <p>Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and policies of those sites, and cannot accept responsibility or liability for their respective privacy practices.</p>
<br />

  <h2><b>Changes to This Policy</b></h2>
  <p>At our discretion, we may change our privacy policy to reflect updates to our business processes, current acceptable practices, or legislative or regulatory changes. If we decide to change this privacy policy, we will post the changes here at the same link by which you are accessing this privacy policy.</p>

  <br />

  <p>If the changes are significant, or if required by applicable law, we will contact you (based on your selected preferences for communications from us) and all our registered users with the new details and links to the updated or changed policy.</p>

  <br />
  <p>If required by law, we will get your permission or give you the opportunity to opt in to or opt out of, as applicable, any new uses of your personal information.</p>
  <br />

  <h2><b>Additional Disclosures for Australian Privacy Act Compliance (AU)</b></h2>
  <br />
  <h3><b>International Transfers of Personal Information</b></h3>
  <br />
  <p>Where the disclosure of your personal information is solely subject to Australian privacy laws, you acknowledge that some third parties may not be regulated by the Privacy Act and the Australian Privacy Principles in the Privacy Act. You acknowledge that if any such third party engages in any act or practice that contravenes the Australian Privacy Principles, it would not be accountable under the Privacy Act, and you will not be able to seek redress under the Privacy Act.</p>
  <br />

  <h2><b>Additional Disclosures for General Data Protection Regulation (GDPR) Compliance (EU)</b></h2>
  <br />
  <h3><b>Data Controller / Data Processor</b></h3>
  <br />
  <p>The GDPR distinguishes between organisations that process personal information for their own purposes (known as “data controllers”) and organizations that process personal information on behalf of other organizations (known as “data processors”). We, Rodeo Commerce, Inc, located at the address provided in our Contact Us section, are a Data Controller with respect to the personal information you provide to us.</p>
  <br />

  <h3><b>Legal Bases for Processing Your Personal Information</b></h3>
  <p>We will only collect and use your personal information when we have a legal right to do so. In which case, we will collect and use your personal information lawfully, fairly, and in a transparent manner. If we seek your consent to process your personal information, and you are under 16 years of age, we will seek your parent or legal guardian’s consent to process your personal information for that specific purpose.</p>
  <br />

  <p>Our lawful bases depend on the services you use and how you use them. This means we only collect and use your information on the following grounds:</p>
  <br />
  <ul>
    <li>- Consent From You</li>
    <li>- Performance of a Contract or Transaction</li>
    <li>- Our Legitimate Interests</li>
    <li>- Compliance with Law</li>
  </ul>
  <br />

  <h3><b>International Transfers Outside of the European Economic Area (EEA)</b></h3>
  <p>We will ensure that any transfer of personal information from countries in the European Economic Area (EEA) to countries outside the EEA will be protected by appropriate safeguards, for example by using standard data protection clauses approved by the European Commission, or the use of binding corporate rules or other legally accepted means.</p>
  <br />

  <h3><b>Your Rights and Controlling Your Personal Information</b></h3>
  <p><b>Restrict:</b> You have the right to request that we restrict the processing of your personal information if (i) you are concerned about the accuracy of your personal information; (ii) you believe your personal information has been unlawfully processed; (iii) you need us to maintain the personal information solely for the purpose of a legal claim; or (iv) we are in the process of considering your objection in relation to processing on the basis of legitimate interests.</p>
  <br />

  <p><b>Objecting to processing:</b> You have the right to object to processing of your personal information that is based on our legitimate interests or public interest. If this is done, we must provide compelling legitimate grounds for the processing which overrides your interests, rights, and freedoms, in order to proceed with the processing of your personal information.</p>
  <br />

  <p><b>Data Portability:</b> You may have the right to request a copy of the personal information we hold about you. Where possible, we will provide this information in CSV format or other easily readable machine format. You may also have the right to request that we transfer this personal information to a third party.</p>
  <br />

  <p><b>Deletion:</b> You may have a right to request that we delete the personal information we hold about you at any time, and we will take reasonable steps to delete your personal information from our current records. If you ask us to delete your personal information, we will let you know how the deletion affects your use of our website or products and services. There may be exceptions to this right for specific legal reasons which, if applicable, we will set out for you in response to your request. If you terminate or delete your account, we will delete your personal information within 90 days of the account termination or deletion.</p>
  <br />

  <h3><b>Additional Disclosures for California Compliance (US)</b></h3>
  <br />
  <h4><b>Under the California Consumer Privacy Act (CCPA), California residents have the right to:</b></h4>
  <br />
  <ul>
    <li>- request that a business that collects a consumer’s personal data disclose the categories and specific pieces of personal data that a business has collected about consumers</li>
    <li>- request that a business delete any personal data about the consumer that a business has collected</li>
    <li>- request that a business that sells a consumer’s personal data not sell the consumer’s personal data</li>
  </ul>
  <br />

  <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us using the contact information below.</p>


  <p>If you are a California resident and would like to opt out of the sale of your personal information, please contact us using the contact information below.</p>
  <br />

  <h2><b>Contact Us</b></h2>
  <p>For any questions or concerns regarding your privacy, you may contact us using the following details:</p>

  <p><strong>Rodeo Commerce, Inc.</strong></p>
  <p><b>Email:</b> <a href="mailto:privacy@rodeocommerce.com">privacy@rodeocommerce.com</a></p>

      </main>

      <IndexSectionFooters10/>
    </div>
  )
}

export default PrivacyPolicyPage;
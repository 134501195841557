import React from 'react';

export default function FeaturesSectionFeatures6() {
    return (
        <React.Fragment>
            <>
                <section className="overflow-hidden">
  <div className="flex flex-wrap -m-8">
    <div className="w-full md:w-1/2 p-8">
      <div className="flex flex-col justify-center h-full pt-16 md:py-16">
        <div className="container px-4 mx-auto">
          <div className="md:max-w-lg mx-auto">
            <h3 className="font-heading mb-6 text-4xl font-semibold">Why Choose Us?</h3>
            <p className="mb-8 text-base text-gray-500 font-medium">Providing value every step of the way.</p>
            <div className="flex flex-wrap -m-3">
              <div className="w-full p-3">
                <div className="flex flex-wrap items-center -m-2">
                  <div className="w-auto p-2">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.6 8.9C11.7 9 11.8 9 12 9H19C19.6 9 20 8.6 20 8C20 7.8 20 7.7 19.9 7.6L16.4 0.6C16.1 0.0999999 15.5 -0.1 15 0.2C14.9 0.3 14.7 0.4 14.6 0.6L11.1 7.6C10.9 8 11.1 8.6 11.6 8.9ZM15.5 3.2L17.4 7H13.6L15.5 3.2ZM4.5 0C2 0 5.21541e-08 2 5.21541e-08 4.5C5.21541e-08 7 2 9 4.5 9C7 9 9 7 9 4.5C9 2 7 0 4.5 0ZM4.5 7C3.1 7 2 5.9 2 4.5C2 3.1 3.1 2 4.5 2C5.9 2 7 3.1 7 4.5C7 5.9 5.9 7 4.5 7ZM8.7 11.3C8.3 10.9 7.7 10.9 7.3 11.3L4.5 14.1L1.7 11.3C1.3 10.9 0.7 10.9 0.3 11.3C-0.1 11.7 -0.1 12.3 0.3 12.7L3.1 15.5L0.3 18.3C-0.1 18.7 -0.1 19.3 0.3 19.7C0.7 20.1 1.3 20.1 1.7 19.7L4.5 16.9L7.3 19.7C7.7 20.1 8.3 20.1 8.7 19.7C9.1 19.3 9.1 18.7 8.7 18.3L5.9 15.5L8.7 12.7C9.1 12.3 9.1 11.7 8.7 11.3ZM19 11H12C11.4 11 11 11.4 11 12V19C11 19.6 11.4 20 12 20H19C19.6 20 20 19.6 20 19V12C20 11.4 19.6 11 19 11ZM18 18H13V13H18V18Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-auto p-2">
                    <p className="text-base text-gray-500 font-medium">Tailored Designs</p>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="flex flex-wrap items-center -m-2">
                  <div className="w-auto p-2">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.6 8.9C11.7 9 11.8 9 12 9H19C19.6 9 20 8.6 20 8C20 7.8 20 7.7 19.9 7.6L16.4 0.6C16.1 0.0999999 15.5 -0.1 15 0.2C14.9 0.3 14.7 0.4 14.6 0.6L11.1 7.6C10.9 8 11.1 8.6 11.6 8.9ZM15.5 3.2L17.4 7H13.6L15.5 3.2ZM4.5 0C2 0 5.21541e-08 2 5.21541e-08 4.5C5.21541e-08 7 2 9 4.5 9C7 9 9 7 9 4.5C9 2 7 0 4.5 0ZM4.5 7C3.1 7 2 5.9 2 4.5C2 3.1 3.1 2 4.5 2C5.9 2 7 3.1 7 4.5C7 5.9 5.9 7 4.5 7ZM8.7 11.3C8.3 10.9 7.7 10.9 7.3 11.3L4.5 14.1L1.7 11.3C1.3 10.9 0.7 10.9 0.3 11.3C-0.1 11.7 -0.1 12.3 0.3 12.7L3.1 15.5L0.3 18.3C-0.1 18.7 -0.1 19.3 0.3 19.7C0.7 20.1 1.3 20.1 1.7 19.7L4.5 16.9L7.3 19.7C7.7 20.1 8.3 20.1 8.7 19.7C9.1 19.3 9.1 18.7 8.7 18.3L5.9 15.5L8.7 12.7C9.1 12.3 9.1 11.7 8.7 11.3ZM19 11H12C11.4 11 11 11.4 11 12V19C11 19.6 11.4 20 12 20H19C19.6 20 20 19.6 20 19V12C20 11.4 19.6 11 19 11ZM18 18H13V13H18V18Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-auto p-2">
                    <p className="text-base text-gray-500 font-medium">Performance Focused</p>
                  </div>
                </div>
              </div>
              <div className="w-full p-3">
                <div className="flex flex-wrap items-center -m-2">
                  <div className="w-auto p-2">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.6 8.9C11.7 9 11.8 9 12 9H19C19.6 9 20 8.6 20 8C20 7.8 20 7.7 19.9 7.6L16.4 0.6C16.1 0.0999999 15.5 -0.1 15 0.2C14.9 0.3 14.7 0.4 14.6 0.6L11.1 7.6C10.9 8 11.1 8.6 11.6 8.9ZM15.5 3.2L17.4 7H13.6L15.5 3.2ZM4.5 0C2 0 5.21541e-08 2 5.21541e-08 4.5C5.21541e-08 7 2 9 4.5 9C7 9 9 7 9 4.5C9 2 7 0 4.5 0ZM4.5 7C3.1 7 2 5.9 2 4.5C2 3.1 3.1 2 4.5 2C5.9 2 7 3.1 7 4.5C7 5.9 5.9 7 4.5 7ZM8.7 11.3C8.3 10.9 7.7 10.9 7.3 11.3L4.5 14.1L1.7 11.3C1.3 10.9 0.7 10.9 0.3 11.3C-0.1 11.7 -0.1 12.3 0.3 12.7L3.1 15.5L0.3 18.3C-0.1 18.7 -0.1 19.3 0.3 19.7C0.7 20.1 1.3 20.1 1.7 19.7L4.5 16.9L7.3 19.7C7.7 20.1 8.3 20.1 8.7 19.7C9.1 19.3 9.1 18.7 8.7 18.3L5.9 15.5L8.7 12.7C9.1 12.3 9.1 11.7 8.7 11.3ZM19 11H12C11.4 11 11 11.4 11 12V19C11 19.6 11.4 20 12 20H19C19.6 20 20 19.6 20 19V12C20 11.4 19.6 11 19 11ZM18 18H13V13H18V18Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-auto p-2">
                    <p className="text-base text-gray-500 font-medium">Mobile-Optimized</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full md:w-1/2 p-8">
      <img src="https://shuffle.dev/placeholder/pictures/1280x1280.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';

export default function IndexSectionLogoClouds4() {
    return (
        <React.Fragment>
            <>
                <section className="py-6 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="text-center mb-5">
      <p className="mb-8 text-xl text-gray-500 font-medium">Built tech trusted by</p>
    </div>
    <div className="flex flex-wrap -m-3">
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/AG1.svg" alt />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/arrae.svg" alt />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/four-sigmatic.svg" alt />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/jot.svg" alt />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/thesis.svg" alt />
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/6 p-3">
        <div className="flex items-center justify-center p-6 h-40">
          <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/earth-breeze.svg" alt />
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';

export default function IndexSectionHeaders1() {

    return (
        <React.Fragment>
            <>
                <section className="overflow-hidden ">
                    <div className="container px-4 mx-auto">
                        <div className="relative flex items-center justify-between py-5">
                            <div className="w-auto">
                                <a className="inline-block" href="/">
                                    <img src="https://assets-global.website-files.com/6481ec2ea815e9af86170e86/6481ec2ea815e9af86170ef1_rodeo%20-%20logo.svg" alt className="h-10"/></a>
                            </div>
                            {/*<div className="w-auto hidden lg:block">*/}
                            {/*    <ul className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 flex items-center">*/}
                            {/*        <li className="mr-12"><a href="/features" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Features</a></li>*/}
                            {/*        <li className="mr-12"><a href="/pricing" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Pricing</a></li>*/}
                            {/*        <li className="mr-12"><a href="/about" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">About</a></li>*/}
                            {/*        <li><a href="/blog" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Blog</a></li>*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            <div className="w-auto hidden lg:block">
                                <div className="flex flex-wrap items-center -m-2">
                                    <div className="auto p-2">
                                        <a href="https://ask.hey.rodeo/intake-agency" target="_blank" className="group relative inline-flex justify-center text-center">
                                            <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">Become A Partner (agencies)</span>
                                            <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200"/>
                                        </a>
                                    </div>
                                    <div className="auto p-2">
                                        <a href="https://ask.hey.rodeo/intake-brand" target="_blank" className="group relative inline-flex justify-center text-center">
                                            <span className="relative inline-flex justify-center z-10 px-4 py-2 font-medium text-white border border-lynch-900">Request Early Access (brands)</span>
                                            <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-auto lg:hidden">
                                <a className="inline-block navbar-burger" href="#">
                                    <svg width={51} height={51} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg" className=" text-lynch-500">
                                        <rect width={56} height={56} rx={28} fill="currentColor"/>
                                        <path d="M37 32H19M37 24H19" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50">
                            <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-80"/>
                            <nav className="relative z-50 px-9 pt-8 bg-white h-full overflow-y-auto">
                                <div className="flex flex-wrap justify-between h-full">
                                    <div className="w-full">
                                        <div className="flex items-center justify-between -m-2">
                                            <div className="w-auto p-2">
                                                <a className="inline-block" href="#">
                                                    <img src="https://assets-global.website-files.com/6481ec2ea815e9af86170e86/6481ec2ea815e9af86170ef1_rodeo%20-%20logo.svg" alt className="h-10"/></a>
                                            </div>
                                            <div className="w-auto p-2">
                                                <a className="navbar-burger text-slate-500" href="#">
                                                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6 18L18 6M6 6L18 18" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round"/>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center py-16 w-full">
                                        {/*<ul>*/}
                                        {/*    <li className="mb-10"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Products</a></li>*/}
                                        {/*    <li className="mb-10"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Solutions</a></li>*/}
                                        {/*    <li className="mb-10"><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Resources</a></li>*/}
                                        {/*    <li><a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">Pricing</a></li>*/}
                                        {/*</ul>*/}
                                    </div>
                                    <div className="flex flex-col justify-end w-full pb-8">
                                        <div className="flex flex-wrap -m-2">
                                            <div className="w-full p-2">
                                                <a className="w-full justify-center group relative inline-flex justify-center text-center" href="https://ask.hey.rodeo/intake-agency" target="_blank">
                                                    <span className="w-full justify-center relative inline-flex justify-center z-10 px-4 py-2 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">Become A Partner (agencies)</span>
                                                    <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200"/>
                                                </a>
                                            </div>
                                            <div className="w-full p-2">
                                                <a className="w-full justify-center group relative inline-flex justify-center text-center" href="https://ask.hey.rodeo/intake-brand" target="_blank">
                                                    <span className="w-full justify-center relative inline-flex justify-center z-10 px-4 py-2 font-medium text-white border border-lynch-900">Request Early Access (brands)</span>
                                                    <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className="container px-4 mx-auto">
                        <div className="flex flex-wrap lg:items-center pt-16 xl:pt-36 pb-5 -m-8">
                            <div className="w-full md:w-1/2 p-8">
                                <div className="md:max-w-xl">
                                    <h2 className="font-heading mb-8 text-5xl font-bold">Effortless Auto-login</h2>
                                    <p className="mb-8 text-xl text-gray-500 font-medium">1 click to Maximize Visitor Data | Boost Sales.</p>
                                    <div className="flex flex-wrap items-center -m-3">
                                        <div className="w-auto p-3">
                                            <a href="https://ask.hey.rodeo/intake-brand" target="_blank" className="group relative inline-flex justify-center text-center">
                                                <span className="relative z-10 inline-flex justify-center px-4 py-3 font-medium text-white border border-lynch-900">Request Early Access</span>
                                                <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                            </a>
                                        </div>
                                        <div className="w-auto p-3">
                                            <a href="#features" className="group relative inline-flex justify-center text-center">
                                                <span className="relative inline-flex justify-center z-10 px-4 py-3 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">Show me how it's different</span>
                                                <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="md:w-1/2 p-8">
                                <div className="mx-auto md:mr-0">
                                    {/* max-w-lg */}
                                    {/* <img src="https://shuffle.dev/placeholder/pictures/1280x960.svg?primary=B57BFF" alt="" class="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img"> */}
                                    <img src="https://static.shuffle.dev/uploads/files/8c/8c5fd4bb6dc3ea497aefe1ad830a4c490b6e2761/rodeo-graph.png" alt className="border-0 image-defauls w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </>
        </React.Fragment>
    );
}


import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import AboutSectionNavigations2 from '../components/navigations/AboutSectionNavigations2';
import AboutSectionTeams3 from '../components/teams/AboutSectionTeams3';
import AboutSectionTestimonials4 from '../components/testimonials/AboutSectionTestimonials4';
import AboutSectionFooters5 from '../components/footers/AboutSectionFooters5';
import FeaturesSectionNavigations2 from "../components/navigations/FeaturesSectionNavigations2";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <FeaturesSectionNavigations2 />
      <AboutSectionTeams3 />
      <AboutSectionTestimonials4 />
      <AboutSectionFooters5 />
    </React.Fragment>
  );
}


import React from 'react';

export default function PricingSectionPricing1() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="md:max-w-xl mx-auto text-center mb-10">
      <h4 className="font-heading mb-5 text-3xl font-semibold">Transparent Pricing</h4>
      <p className="mb-8 text-base text-gray-500 font-medium">Find the perfect plan for your business.</p>
      <div className="relative max-w-sm mx-auto">
        <div className="relative z-10 p-1 border border-lynch-900">
          <div className="flex flex-wrap items-center -m-0.5">
            <div className="flex-1 p-0.5">
              <div className><a href="#" className="block px-4 py-2 text-center font-medium text-white bg-lynch-600 border border-lynch-900">Monthly</a></div>
            </div>
            <div className="flex-1 p-0.5">
              <div className><a href="#" className="block px-4 py-2 text-center font-medium text-gray-900 bg-transparent border border-transparent">Annual</a></div>
            </div>
          </div>
        </div>
        <span className="absolute top-1 left-1 w-full h-full bg-lynch-50" />
      </div>
    </div>
    <div className="flex flex-wrap -m-3">
      <div className="w-full md:w-1/3 p-3">
        <div className="p-8 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-auto">
              <div className="text-center">
                <h6 className="font-heading mb-3 text-xl font-semibold">Starter</h6>
                <h2 className="font-heading mb-8 text-5xl font-bold">$34.99</h2>
              </div>
              <ul className="mb-6">
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">5 Page Custom Website</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Basic SEO Setup</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Mobile Responsive Design</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">1 Month Free Support</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">CMS Integration</p>
                </li>
              </ul>
            </div>
            <div className="w-auto">
              <a href="#" className="group relative flex justify-center w-full text-center">
                <span className="relative z-10 flex justify-center w-full px-4 py-3 font-medium text-white border border-lynch-900">Subscribe</span>
                <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-3">
        <div className="p-8 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-auto">
              <div className="text-center">
                <h6 className="font-heading mb-3 text-xl font-semibold">Pro</h6>
                <h2 className="font-heading mb-8 text-5xl font-bold">$65.99</h2>
              </div>
              <ul className="mb-6">
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">10 Page Custom Website</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Advanced SEO Setup</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Performance Optimization</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">3 Months Free Support</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">E-commerce Functionality</p>
                </li>
              </ul>
            </div>
            <div className="w-auto">
              <a href="#" className="group relative flex justify-center w-full text-center">
                <span className="relative z-10 flex justify-center w-full px-4 py-3 font-medium text-white border border-lynch-900">Subscribe</span>
                <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-3">
        <div className="p-8 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-auto">
              <div className="text-center">
                <h6 className="font-heading mb-3 text-xl font-semibold">Premium</h6>
                <h2 className="font-heading mb-8 text-5xl font-bold">$99.99</h2>
              </div>
              <ul className="mb-6">
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Unlimited Page Custom Website</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Complete SEO Suite</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">CDN &amp; Security Setup</p>
                </li>
                <li className="mb-4 flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">6 Months Free Support</p>
                </li>
                <li className="flex items-center">
                  <div className="mr-3">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-8 h-8 text-white bg-lynch-500 border border-lynch-900 rounded-full">
                        <svg width={8} height={6} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.35497 0.605052C7.30849 0.558188 7.25319 0.520991 7.19226 0.495607C7.13133 0.470222 7.06598 0.457153 6.99997 0.457153C6.93397 0.457153 6.86862 0.470222 6.80769 0.495607C6.74676 0.520991 6.69146 0.558188 6.64498 0.605052L2.91998 4.33505L1.35497 2.76505C1.30671 2.71843 1.24974 2.68178 1.18732 2.65717C1.12489 2.63257 1.05823 2.62051 0.991136 2.62167C0.924046 2.62283 0.857841 2.63719 0.796302 2.66394C0.734763 2.69069 0.679094 2.72929 0.632475 2.77755C0.585856 2.82581 0.549199 2.88278 0.524597 2.94521C0.499995 3.00764 0.487929 3.0743 0.48909 3.14139C0.490251 3.20848 0.504615 3.27469 0.531362 3.33623C0.558108 3.39776 0.596714 3.45343 0.644975 3.50005L2.56498 5.42005C2.61146 5.46692 2.66676 5.50411 2.72769 5.5295C2.78862 5.55488 2.85397 5.56795 2.91998 5.56795C2.98598 5.56795 3.05133 5.55488 3.11226 5.5295C3.17319 5.50411 3.22849 5.46692 3.27498 5.42005L7.35497 1.34005C7.40573 1.29323 7.44623 1.23641 7.47393 1.17316C7.50164 1.10991 7.51594 1.0416 7.51594 0.972553C7.51594 0.903502 7.50164 0.835199 7.47393 0.771949C7.44623 0.7087 7.40573 0.651874 7.35497 0.605052Z" fill="currentColor" />
                        </svg>
                      </div>
                      <span className="absolute top-1 left-1 w-full h-full bg-lynch-200 rounded-full transition duration-200" />
                    </div>
                  </div>
                  <p className="text-base text-gray-500 font-medium">Dedicated Account Manager</p>
                </li>
              </ul>
            </div>
            <div className="w-auto">
              <a href="#" className="group relative flex justify-center w-full text-center">
                <span className="relative z-10 flex justify-center w-full px-4 py-3 font-medium text-white border border-lynch-900">Subscribe</span>
                <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React, {useState} from 'react';

export default function IndexSectionNewsletter11() {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/20474847/337bfca5-de83-4667-8145-db2a11b5dde7', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fields: [
                    {
                        name: 'email',
                        value: email,
                    },
                ],
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.inlineMessage === 'error') {
                    setErrorMessage('There was an error submitting the form. Please try again.');
                } else {
                    setIsSubmitted(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrorMessage('There was an error submitting the form. Please try again.');
            });
    };

    return (
        <React.Fragment>
            <section className="overflow-hidden pt-0">
                <div className="container px-4 mx-auto py-20">
                    <div className="p-8 mb-14 bg-white border border-slate-200 rounded-xl">
                        <div className="flex flex-wrap items-center -m-4">
                            <div className="w-full md:w-1/2 p-4">
                                <div className="md:max-w-lg">
                                    <h6 className="font-heading mb-3 text-xl font-semibold">
                                        We made a tool that fixes email/SMS' Achilles Heel...
                                    </h6>
                                    <p className="text-base text-gray-500 font-medium">
                                        When customers click your link, they land on your site anonymously... UNTIL NOW
                                    </p>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 p-4">
                                <div className="flex flex-wrap lg:justify-end -m-2">
                                    <div className="w-full lg:max-w-2xl p-2">
                                        <div className="w-full justify-end">
                                            {isSubmitted ? (
                                                <div className="text-base text-green-500 font-medium">
                                                    Awesome! Keep an eye out for the goods.
                                                </div>
                                            ) : (
                                                <form onSubmit={handleSubmit} className="flex items-center p-4">
                                                    <input
                                                        type="email"
                                                        placeholder="Enter your email address"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                        className=" flex-grow px-4 py-3 text-base font-medium disabled:bg-white placeholder-gray-500 disabled:placeholder-gray-300 outline-none border border-lynch-900 active:border-lynch-600 disabled:border-gray-200 mr-4 "
                                                    />
                                                    <button type="submit" className="group relative inline-flex justify-center text-center">
                            <span className="relative z-10 inline-flex justify-center px-4 py-3 font-medium text-white border border-lynch-900 sm:w-auto">
                              Subscribe
                            </span>
                                                        <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                                    </button>
                                                </form>
                                            )}
                                            {errorMessage && (
                                                <div className="text-red-500 mt-2">{errorMessage}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

import React from 'react';

export default function AboutSectionTeams3() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="md:max-w-2xl text-center mx-auto">
      <h4 className="font-heading text-3xl font-semibold">Our Team</h4>
      <h4 className="font-heading mb-5 text-3xl font-semibold">Meet the Team</h4>
      <p className="mb-8 text-base text-gray-500 font-medium">The brains behind your next digital success.</p>
    </div>
    <div className="flex flex-wrap justify-center -m-2.5 mb-14">
      <div className="w-auto p-2.5">
        <a href="#" className="group relative inline-flex justify-center text-center">
          <span className="relative z-10 inline-flex justify-center px-4 py-3 font-medium text-white border border-lynch-900">Join our team</span>
          <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200" />
        </a>
      </div>
      <div className="w-auto p-2.5">
        <a href="#" className="group relative inline-flex justify-center text-center">
          <span className="relative inline-flex justify-center z-10 px-4 py-3 font-medium group-hover:text-white group-focus:text-white border border-amethyst-900 transition duration-200">Meet our team</span>
          <span className="absolute top-1 left-1 w-full h-full bg-lynch-50 group-hover:bg-amethyst-500 group-focus:bg-amethyst-600 transition duration-200" />
        </a>
      </div>
    </div>
    <div className="flex flex-wrap -m-3">
      <div className="w-full md:w-1/2 p-3">
        <div className="p-10 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-wrap -m-4">
            <div className="w-auto p-4">
              <div className="w-36">
                <img src="https://shuffle.dev/placeholder/pictures/1280x1280.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
              </div>
            </div>
            <div className="lg:flex-1 p-4">
              <h6 className="font-heading mb-3 text-xl font-semibold">Jordan Smith</h6>
              <p className="mb-8 text-lg text-gray-500 font-medium">CEO &amp; Founder</p>
              <p className="mb-8 text-base text-gray-500 font-medium">Visionary leader with a passion for innovation.</p>
              <div className="flex flex-wrap -m-2">
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1103_620)">
                        <path d="M21 0H3C1.3455 0 0 1.3455 0 3V21C0 22.6545 1.3455 24 3 24H21C22.6545 24 24 22.6545 24 21V3C24 1.3455 22.6545 0 21 0Z" fill="currentColor" />
                        <path d="M20.25 12H16.5V9C16.5 8.172 17.172 8.25 18 8.25H19.5V4.5H16.5C14.0145 4.5 12 6.5145 12 9V12H9V15.75H12V24H16.5V15.75H18.75L20.25 12Z" fill="#FAFAFA" />
                      </g>
                      <defs><clipPath id="clip0_1103_620"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_1103_623)">
                        <path d="M11.9945 23.9982C18.6196 23.9982 23.9903 18.6276 23.9903 12.0025C23.9903 5.3774 18.6196 0.00671387 11.9945 0.00671387C5.36947 0.00671387 -0.0012207 5.3774 -0.0012207 12.0025C-0.0012207 18.6276 5.36947 23.9982 11.9945 23.9982Z" fill="currentColor" stroke="white" strokeMiterlimit={10} />
                        <path d="M4.60511 5.05249L10.3389 12.719L4.56897 18.9523H5.86766L10.9194 13.4949L15.0009 18.9523H19.4201L13.3635 10.8546L18.7342 5.05249H17.4355L12.7833 10.0785L9.0243 5.05249H4.60511ZM6.51489 6.009H8.54504L17.51 17.9958H15.4799L6.51489 6.009Z" fill="white" />
                      </g>
                      <defs><clipPath id="clip0_1103_623"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-3">
        <div className="p-10 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-wrap -m-4">
            <div className="w-auto p-4">
              <div className="w-36">
                <img src="https://shuffle.dev/placeholder/pictures/1280x1280.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
              </div>
            </div>
            <div className="lg:flex-1 p-4">
              <h6 className="font-heading mb-3 text-xl font-semibold">Alex Taylor</h6>
              <p className="mb-8 text-lg text-gray-500 font-medium">CTO</p>
              <p className="mb-8 text-base text-gray-500 font-medium">Tech guru driving our digital excellence.</p>
              <div className="flex flex-wrap -m-2">
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1103_620)">
                        <path d="M21 0H3C1.3455 0 0 1.3455 0 3V21C0 22.6545 1.3455 24 3 24H21C22.6545 24 24 22.6545 24 21V3C24 1.3455 22.6545 0 21 0Z" fill="currentColor" />
                        <path d="M20.25 12H16.5V9C16.5 8.172 17.172 8.25 18 8.25H19.5V4.5H16.5C14.0145 4.5 12 6.5145 12 9V12H9V15.75H12V24H16.5V15.75H18.75L20.25 12Z" fill="#FAFAFA" />
                      </g>
                      <defs><clipPath id="clip0_1103_620"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_1103_623)">
                        <path d="M11.9945 23.9982C18.6196 23.9982 23.9903 18.6276 23.9903 12.0025C23.9903 5.3774 18.6196 0.00671387 11.9945 0.00671387C5.36947 0.00671387 -0.0012207 5.3774 -0.0012207 12.0025C-0.0012207 18.6276 5.36947 23.9982 11.9945 23.9982Z" fill="currentColor" stroke="white" strokeMiterlimit={10} />
                        <path d="M4.60511 5.05249L10.3389 12.719L4.56897 18.9523H5.86766L10.9194 13.4949L15.0009 18.9523H19.4201L13.3635 10.8546L18.7342 5.05249H17.4355L12.7833 10.0785L9.0243 5.05249H4.60511ZM6.51489 6.009H8.54504L17.51 17.9958H15.4799L6.51489 6.009Z" fill="white" />
                      </g>
                      <defs><clipPath id="clip0_1103_623"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M12 0C5.3724 0 0 5.3808 0 12.0204C0 17.3304 3.438 21.8364 8.2068 23.4252C8.8068 23.5356 9.0252 23.1648 9.0252 22.8456C9.0252 22.5612 9.0156 21.804 9.0096 20.802C5.6712 21.528 4.9668 19.1904 4.9668 19.1904C4.422 17.8008 3.6348 17.4312 3.6348 17.4312C2.5452 16.6872 3.7176 16.7016 3.7176 16.7016C4.9212 16.7856 5.5548 17.94 5.5548 17.94C6.6252 19.776 8.364 19.2456 9.0468 18.9384C9.1572 18.162 9.4668 17.6328 9.81 17.3328C7.146 17.0292 4.344 15.9972 4.344 11.3916C4.344 10.08 4.812 9.006 5.5788 8.166C5.4552 7.8624 5.0436 6.6396 5.6964 4.986C5.6964 4.986 6.7044 4.662 8.9964 6.2172C9.97532 5.95022 10.9853 5.81423 12 5.8128C13.02 5.8176 14.046 5.9508 15.0048 6.2172C17.2956 4.662 18.3012 4.9848 18.3012 4.9848C18.9564 6.6396 18.5436 7.8624 18.4212 8.166C19.1892 9.006 19.6548 10.08 19.6548 11.3916C19.6548 16.0092 16.848 17.0256 14.1756 17.3232C14.6064 17.694 14.9892 18.4272 14.9892 19.5492C14.9892 21.1548 14.9748 22.452 14.9748 22.8456C14.9748 23.1672 15.1908 23.5416 15.8004 23.424C18.19 22.6225 20.2672 21.0904 21.7386 19.0441C23.2099 16.9977 24.001 14.5408 24 12.0204C24 5.3808 18.6264 0 12 0Z" fill="currentColor" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-3">
        <div className="p-10 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-wrap -m-4">
            <div className="w-auto p-4">
              <div className="w-36">
                <img src="https://shuffle.dev/placeholder/pictures/1280x1280.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
              </div>
            </div>
            <div className="lg:flex-1 p-4">
              <h6 className="font-heading mb-3 text-xl font-semibold">Riley Jones</h6>
              <p className="mb-8 text-lg text-gray-500 font-medium">Creative Director</p>
              <p className="mb-8 text-base text-gray-500 font-medium">Crafting visual masterpieces every day.</p>
              <div className="flex flex-wrap -m-2">
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1103_620)">
                        <path d="M21 0H3C1.3455 0 0 1.3455 0 3V21C0 22.6545 1.3455 24 3 24H21C22.6545 24 24 22.6545 24 21V3C24 1.3455 22.6545 0 21 0Z" fill="currentColor" />
                        <path d="M20.25 12H16.5V9C16.5 8.172 17.172 8.25 18 8.25H19.5V4.5H16.5C14.0145 4.5 12 6.5145 12 9V12H9V15.75H12V24H16.5V15.75H18.75L20.25 12Z" fill="#FAFAFA" />
                      </g>
                      <defs><clipPath id="clip0_1103_620"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_1103_623)">
                        <path d="M11.9945 23.9982C18.6196 23.9982 23.9903 18.6276 23.9903 12.0025C23.9903 5.3774 18.6196 0.00671387 11.9945 0.00671387C5.36947 0.00671387 -0.0012207 5.3774 -0.0012207 12.0025C-0.0012207 18.6276 5.36947 23.9982 11.9945 23.9982Z" fill="currentColor" stroke="white" strokeMiterlimit={10} />
                        <path d="M4.60511 5.05249L10.3389 12.719L4.56897 18.9523H5.86766L10.9194 13.4949L15.0009 18.9523H19.4201L13.3635 10.8546L18.7342 5.05249H17.4355L12.7833 10.0785L9.0243 5.05249H4.60511ZM6.51489 6.009H8.54504L17.51 17.9958H15.4799L6.51489 6.009Z" fill="white" />
                      </g>
                      <defs><clipPath id="clip0_1103_623"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-3">
        <div className="p-10 h-full bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-wrap -m-4">
            <div className="w-auto p-4">
              <div className="w-36">
                <img src="https://shuffle.dev/placeholder/pictures/1280x1280.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
              </div>
            </div>
            <div className="lg:flex-1 p-4">
              <h6 className="font-heading mb-3 text-xl font-semibold">Casey Johnson</h6>
              <p className="mb-8 text-lg text-gray-500 font-medium">Head of Marketing</p>
              <p className="mb-8 text-base text-gray-500 font-medium">Strategist turning clicks into customers.</p>
              <div className="flex flex-wrap -m-2">
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_1103_620)">
                        <path d="M21 0H3C1.3455 0 0 1.3455 0 3V21C0 22.6545 1.3455 24 3 24H21C22.6545 24 24 22.6545 24 21V3C24 1.3455 22.6545 0 21 0Z" fill="currentColor" />
                        <path d="M20.25 12H16.5V9C16.5 8.172 17.172 8.25 18 8.25H19.5V4.5H16.5C14.0145 4.5 12 6.5145 12 9V12H9V15.75H12V24H16.5V15.75H18.75L20.25 12Z" fill="#FAFAFA" />
                      </g>
                      <defs><clipPath id="clip0_1103_620"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
                <div className="w-auto p-2">
                  <a href="#" className="inline-block text-lynch-300 hover:text-lynch-500 transition duration-200">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_1103_623)">
                        <path d="M11.9945 23.9982C18.6196 23.9982 23.9903 18.6276 23.9903 12.0025C23.9903 5.3774 18.6196 0.00671387 11.9945 0.00671387C5.36947 0.00671387 -0.0012207 5.3774 -0.0012207 12.0025C-0.0012207 18.6276 5.36947 23.9982 11.9945 23.9982Z" fill="currentColor" stroke="white" strokeMiterlimit={10} />
                        <path d="M4.60511 5.05249L10.3389 12.719L4.56897 18.9523H5.86766L10.9194 13.4949L15.0009 18.9523H19.4201L13.3635 10.8546L18.7342 5.05249H17.4355L12.7833 10.0785L9.0243 5.05249H4.60511ZM6.51489 6.009H8.54504L17.51 17.9958H15.4799L6.51489 6.009Z" fill="white" />
                      </g>
                      <defs><clipPath id="clip0_1103_623"><rect width={24} height={24} fill="white" /></clipPath></defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import FeaturesSectionNavigations2 from '../components/navigations/FeaturesSectionNavigations2';
import FeaturesSectionFeatures3 from '../components/features/FeaturesSectionFeatures3';
import FeaturesSectionFeatures4 from '../components/features/FeaturesSectionFeatures4';
import FeaturesSectionFeatures5 from '../components/features/FeaturesSectionFeatures5';
import FeaturesSectionFeatures6 from '../components/features/FeaturesSectionFeatures6';
import FeaturesSectionTestimonials7 from '../components/testimonials/FeaturesSectionTestimonials7';
import FeaturesSectionFeatures8 from '../components/features/FeaturesSectionFeatures8';
import FeaturesSectionTestimonials9 from '../components/testimonials/FeaturesSectionTestimonials9';
import FeaturesSectionFooters11 from '../components/footers/FeaturesSectionFooters11';

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function Features() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <FeaturesSectionNavigations2 />
      <FeaturesSectionFeatures3 />
      <FeaturesSectionFeatures4 />
      <FeaturesSectionFeatures5 />
      <FeaturesSectionFeatures6 />
      <FeaturesSectionTestimonials7 />
      <FeaturesSectionFeatures8 />
      <FeaturesSectionTestimonials9 />
      <FeaturesSectionFooters11 />
    </React.Fragment>
  );
}


import React, {useState} from 'react';
import "./styles.css";

export default function IndexSectionFooters10() {
    const currentYear = new Date().getFullYear();
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://api.hsforms.com/submissions/v3/integration/submit/20474847/337bfca5-de83-4667-8145-db2a11b5dde7', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                fields: [
                    {
                        name: 'email',
                        value: email,
                    },
                ],
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.inlineMessage === 'error') {
                    setErrorMessage('There was an error submitting the form. Please try again.');
                } else {
                    setIsSubmitted(true);
                }
            })
            .catch((error) => {
                console.error('Error:', error);
                setErrorMessage('There was an error submitting the form. Please try again.');
            });
    };

    return (
        <React.Fragment>
            <section className="pt-16 overflow-hidden">
                <div className="container px-4 mx-auto">
                    <div className="p-8 mb-14 bg-white border border-slate-200 rounded-xl">
                        <div className="flex flex-wrap items-center -m-4">
                            <div className="w-full md:w-1/2 p-4">
                                <div className="md:max-w-lg">
                                    <h6 className="font-heading mb-3 text-xl font-semibold">
                                        Learn the secret to Retention Marketing in 2024
                                    </h6>
                                    <p className="text-base text-gray-500 font-medium">
                                        Stay in touch to get the goods.
                                    </p>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 p-4">
                                <div className="flex flex-wrap lg:justify-end -m-2">
                                    <div className="w-full lg:max-w-2xl p-2">
                                        <div className="w-full justify-end">
                                            {isSubmitted ? (
                                                <div className="text-base text-green-500 font-medium">
                                                    Awesome! Keep an eye out for the goods.
                                                </div>
                                            ) : (
                                                <form onSubmit={handleSubmit} className="flex flex-row items-center">
                                                    <input
                                                        type="email"
                                                        placeholder="Enter your email address"
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                        className="footer-input-field flex-grow px-4 py-3 text-base font-medium disabled:bg-white placeholder-gray-500 disabled:placeholder-gray-300 outline-none border border-lynch-900 active:border-lynch-600 disabled:border-gray-200 mr-4 flex-1"
                                                    />

                                                    <button
                                                        type="submit"
                                                        className="group relative inline-flex justify-center text-center"
                                                    >
                                                        <span className="relative z-10 inline-flex justify-center px-4 py-3 font-medium text-white border border-lynch-900 sm:w-auto">
                                                        Yeehaw!
                                                        </span>
                                                        <span className="absolute top-1 left-1 w-full h-full bg-lynch-500 group-hover:bg-lynch-600 group-focus:bg-lynch-700 transition duration-200"/>
                                                    </button>
                                                </form>
                                            )}
                                            {errorMessage && (
                                                <div className="text-red-500 mt-2">{errorMessage}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pb-12 border-b border-slate-100">
                        <div className="flex flex-wrap -m-8">
                            <div className="w-full md:w-1/2 lg:w-4/12 p-8">
                                <div className="max-w-xs">
                                    <div className="mb-6">
                                        <img
                                            src="https://assets-global.website-files.com/6481ec2ea815e9af86170e86/6481ec2ea815e9af86170ef1_rodeo%20-%20logo.svg"
                                            alt=""
                                            className="h-10"
                                        />
                                    </div>
                                    <p className="text-base text-gray-500 font-medium">Because it ain't our first</p>
                                </div>
                            </div>
                            {/*<div className="w-full md:w-1/2 lg:flex-1 p-8">*/}
                            {/*  <ul>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="/" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Home*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</div>*/}
                            {/*<div className="w-full md:w-1/2 lg:flex-1 p-8">*/}
                            {/*  <ul>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Features*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-light text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Testimonials*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</div>*/}
                            {/*<div className="w-full md:w-1/2 lg:flex-1 p-8">*/}
                            {/*  <ul>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Pricing*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-light text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        FAQ*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</div>*/}
                            {/*<div className="w-full md:w-1/2 lg:flex-1 p-8">*/}
                            {/*  <ul>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        About*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-light text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Mission*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</div>*/}
                            {/*<div className="w-full md:w-1/2 lg:flex-1 p-8">*/}
                            {/*  <ul>*/}
                            {/*    <li className="mb-3">*/}
                            {/*      <a href="#" className="inline-block font-medium text-base text-gray-500 hover:text-gray-600">*/}
                            {/*        Blog*/}
                            {/*      </a>*/}
                            {/*    </li>*/}
                            {/*  </ul>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className="py-8 text-center">
                        <p className="text-base text-gray-500 font-medium">&copy; {currentYear} Rodeo. All rights reserved.</p>

                        <div className='mt-2 flex justify-center items-center gap-2 text-gray-500 font-medium'>
                            <a className='text-sm hover-underline' href="/privacy-policy">Privacy Policy</a>
                            
                            <svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 24 24" fill="#6b7280" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle"><circle cx="12" cy="12" r="10"/></svg>

                            <a className='text-sm hover-underline' href="/terms-of-service">Terms of Services</a>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

import React from 'react';

export default function FeaturesSectionTestimonials7() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="flex flex-wrap -m-3">
      <div className="w-full md:w-1/2 p-3">
        <div className="py-12 px-12 h-full text-center bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-full">
              <div className="flex flex-wrap justify-center -m-1 mb-12">
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
              </div>
              <div className="mb-12">
                <h4 className="font-heading text-3xl font-semibold">Revolutionized our online presence with a stunning site.</h4>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2 max-w-max mx-auto">
                <div className="relative inline-flex">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.71 12.71C16.6904 11.9387 17.406 10.8809 17.7572 9.68394C18.1085 8.48697 18.0779 7.21027 17.6698 6.03147C17.2617 4.85267 16.4963 3.83039 15.4801 3.10686C14.4639 2.38332 13.2474 1.99451 12 1.99451C10.7525 1.99451 9.53611 2.38332 8.51993 3.10686C7.50374 3.83039 6.73834 4.85267 6.33021 6.03147C5.92208 7.21027 5.89151 8.48697 6.24276 9.68394C6.59401 10.8809 7.3096 11.9387 8.29 12.71C6.61007 13.383 5.14428 14.4994 4.04889 15.9399C2.95349 17.3805 2.26956 19.0913 2.07 20.89C2.05555 21.0213 2.06711 21.1542 2.10402 21.2811C2.14093 21.4079 2.20246 21.5263 2.28511 21.6293C2.45202 21.8375 2.69478 21.9708 2.96 22C3.22521 22.0292 3.49116 21.9518 3.69932 21.7849C3.90749 21.618 4.04082 21.3752 4.07 21.11C4.28958 19.1552 5.22168 17.3498 6.68822 16.0388C8.15475 14.7278 10.0529 14.003 12.02 14.003C13.9871 14.003 15.8852 14.7278 17.3518 16.0388C18.8183 17.3498 19.7504 19.1552 19.97 21.11C19.9972 21.3557 20.1144 21.5827 20.2991 21.747C20.4838 21.9114 20.7228 22.0015 20.97 22H21.08C21.3421 21.9698 21.5817 21.8373 21.7466 21.6313C21.9114 21.4252 21.9881 21.1624 21.96 20.9C21.7595 19.0962 21.0719 17.381 19.9708 15.9382C18.8698 14.4954 17.3969 13.3795 15.71 12.71ZM12 12C11.2089 12 10.4355 11.7654 9.77772 11.3259C9.11992 10.8864 8.60723 10.2616 8.30448 9.53074C8.00173 8.79983 7.92251 7.99557 8.07686 7.21964C8.2312 6.44372 8.61216 5.73099 9.17157 5.17158C9.73098 4.61217 10.4437 4.2312 11.2196 4.07686C11.9956 3.92252 12.7998 4.00173 13.5307 4.30448C14.2616 4.60724 14.8863 5.11993 15.3259 5.77772C15.7654 6.43552 16 7.20888 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12Z" fill="currentColor" />
                  </svg>
                </div>
              </div>
              <h6 className="font-heading text-xl font-semibold">Emma Smith</h6>
              <p className="text-sm text-gray-500 font-medium">Marketing Manager at <a href="#" className="inline-block font-medium text-sm text-gray-700 hover:text-gray-900">Shuffle</a></p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-1/2 p-3">
        <div className="py-12 px-12 h-full text-center bg-white border border-slate-200 rounded-xl">
          <div className="flex flex-col justify-between h-full">
            <div className="w-full">
              <div className="flex flex-wrap justify-center -m-1 mb-12">
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
                <div className="w-auto p-1">
                  <svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="text-lynch-300">
                    <path d="M8.07668 0.719929C8.41827 -0.101364 9.58173 -0.101364 9.92332 0.719928L11.4105 4.29546C11.5545 4.6417 11.8801 4.87827 12.2539 4.90823L16.114 5.2177C17.0006 5.28878 17.3601 6.39529 16.6846 6.97396L13.7436 9.49322C13.4588 9.73717 13.3345 10.1199 13.4215 10.4847L14.32 14.2515C14.5264 15.1167 13.5851 15.8006 12.826 15.3369L9.52125 13.3184C9.20124 13.1229 8.79876 13.1229 8.47875 13.3184L5.17397 15.3369C4.41487 15.8006 3.47362 15.1167 3.68001 14.2515L4.57852 10.4847C4.66553 10.1199 4.54116 9.73717 4.25637 9.49322L1.31539 6.97396C0.639854 6.39529 0.99938 5.28878 1.88603 5.2177L5.74612 4.90823C6.11991 4.87827 6.44552 4.6417 6.58953 4.29546L8.07668 0.719929Z" fill="currentColor" />
                  </svg>
                </div>
              </div>
              <div className="mb-12">
                <h4 className="font-heading text-3xl font-semibold">Revolutionized our online presence with a stunning site.</h4>
              </div>
            </div>
            <div className="w-full">
              <div className="mb-2 max-w-max mx-auto">
                <div className="relative inline-flex">
                  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.71 12.71C16.6904 11.9387 17.406 10.8809 17.7572 9.68394C18.1085 8.48697 18.0779 7.21027 17.6698 6.03147C17.2617 4.85267 16.4963 3.83039 15.4801 3.10686C14.4639 2.38332 13.2474 1.99451 12 1.99451C10.7525 1.99451 9.53611 2.38332 8.51993 3.10686C7.50374 3.83039 6.73834 4.85267 6.33021 6.03147C5.92208 7.21027 5.89151 8.48697 6.24276 9.68394C6.59401 10.8809 7.3096 11.9387 8.29 12.71C6.61007 13.383 5.14428 14.4994 4.04889 15.9399C2.95349 17.3805 2.26956 19.0913 2.07 20.89C2.05555 21.0213 2.06711 21.1542 2.10402 21.2811C2.14093 21.4079 2.20246 21.5263 2.28511 21.6293C2.45202 21.8375 2.69478 21.9708 2.96 22C3.22521 22.0292 3.49116 21.9518 3.69932 21.7849C3.90749 21.618 4.04082 21.3752 4.07 21.11C4.28958 19.1552 5.22168 17.3498 6.68822 16.0388C8.15475 14.7278 10.0529 14.003 12.02 14.003C13.9871 14.003 15.8852 14.7278 17.3518 16.0388C18.8183 17.3498 19.7504 19.1552 19.97 21.11C19.9972 21.3557 20.1144 21.5827 20.2991 21.747C20.4838 21.9114 20.7228 22.0015 20.97 22H21.08C21.3421 21.9698 21.5817 21.8373 21.7466 21.6313C21.9114 21.4252 21.9881 21.1624 21.96 20.9C21.7595 19.0962 21.0719 17.381 19.9708 15.9382C18.8698 14.4954 17.3969 13.3795 15.71 12.71ZM12 12C11.2089 12 10.4355 11.7654 9.77772 11.3259C9.11992 10.8864 8.60723 10.2616 8.30448 9.53074C8.00173 8.79983 7.92251 7.99557 8.07686 7.21964C8.2312 6.44372 8.61216 5.73099 9.17157 5.17158C9.73098 4.61217 10.4437 4.2312 11.2196 4.07686C11.9956 3.92252 12.7998 4.00173 13.5307 4.30448C14.2616 4.60724 14.8863 5.11993 15.3259 5.77772C15.7654 6.43552 16 7.20888 16 8C16 9.06087 15.5786 10.0783 14.8284 10.8284C14.0783 11.5786 13.0609 12 12 12Z" fill="currentColor" />
                  </svg>
                </div>
              </div>
              <h6 className="font-heading text-xl font-semibold">Emma Smith</h6>
              <p className="text-sm text-gray-500 font-medium">Marketing Manager at <a href="#" className="inline-block font-medium text-sm text-gray-700 hover:text-gray-900">Shuffle</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}


import React from 'react';
import {Helmet, HelmetProvider} from 'react-helmet-async';
import IndexSectionHeaders1 from '../components/headers/IndexSectionHeaders1';
import IndexSectionNewsletter11 from '../components/newsletter/IndexSectionNewsletter11';
import IndexSectionLogoClouds4 from '../components/logo-clouds/IndexSectionLogoClouds4';
import IndexSectionTestimonials14 from '../components/testimonials/IndexSectionTestimonials14';
import IndexSectionFeatures5 from '../components/features/IndexSectionFeatures5';
import IndexSectionFeatures6 from '../components/features/IndexSectionFeatures6';
import IndexSectionFeatures7 from '../components/features/IndexSectionFeatures7';
import IndexSectionTestimonials15 from '../components/testimonials/IndexSectionTestimonials15';
import IndexSectionBlog8 from '../components/blog/IndexSectionBlog8';
import IndexSectionFooters10 from '../components/footers/IndexSectionFooters10';

const meta = {
    title: 'Rodeo - Nextgen retention marketing platform for DTC',
    meta: [],
    link: [
        {rel: 'icon', href: '/favicon.ico'} // Add your favicon path here
    ],
    style: [],
    script: [],
};

export default function Index() {
    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta}></Helmet>
            </HelmetProvider>
            <IndexSectionHeaders1/>
            <IndexSectionNewsletter11/>
            <IndexSectionLogoClouds4/>
            <IndexSectionTestimonials14/>
            <IndexSectionFeatures5/>
            <IndexSectionFeatures6/>
            <IndexSectionFeatures7/>
            <IndexSectionTestimonials15/>
            {/*<IndexSectionBlog8 />*/}
            <IndexSectionFooters10/>
        </React.Fragment>
    );
}


import React from 'react';

export default function BlogSectionBlog1() {
    return (
        <React.Fragment>
            <>
                <section className="py-20 overflow-hidden">
  <div className="container px-4 mx-auto">
    <div className="md:max-w-2xl mb-16">
      <div className="relative inline-block mb-5">
        <span className="relative z-10 px-3 py-0.5 text-sm font-bold text-white border border-gray-900">Blog</span>
        <span className="absolute top-1 left-1 w-full h-full bg-lynch-600" />
      </div>
      <h2 className="font-heading mb-8 text-5xl font-bold">Latest articles</h2>
      <p className="mb-8 text-base text-gray-500 font-medium">Latest Insights</p>
    </div>
    <div className="flex flex-wrap -m-5">
      <div className="w-full p-5">
        <div className="flex flex-wrap -m-6 xl:-m-16">
          <div className="w-full md:w-1/2 lg:w-auto p-6 xl:p-16">
            <div className="max-w-xl mx-auto">
              <img src="https://shuffle.dev/placeholder/pictures/1280x720.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
            </div>
          </div>
          <div className="flex-1 md:flex-1 p-6 xl:p-16">
            <div className="flex flex-col justify-between h-full">
              <div className="w-auto mb-9">
                <div className="md:max-w-md">
                  <div className="relative inline-block mb-5">
                    <span className="relative z-10 px-3 py-0.5 text-sm font-bold text-white border border-gray-900">Category</span>
                    <span className="absolute top-1 left-1 w-full h-full bg-lynch-600" />
                  </div>
                  <h5 className="font-heading mb-4 text-2xl font-semibold"><a href="#">The Future of Interactive Web Design</a></h5>
                  <p className="text-lg text-gray-500 font-medium">Diving into web trends for the next decade.</p>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center -m-1.5">
                  <div className="w-auto p-1.5">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5142 11.0768C14.3107 10.4501 14.8922 9.5907 15.1775 8.61815C15.4629 7.64561 15.4381 6.60829 15.1065 5.65052C14.7749 4.69274 14.153 3.86214 13.3273 3.27427C12.5017 2.6864 11.5133 2.37048 10.4998 2.37048C9.48623 2.37048 8.49788 2.6864 7.67223 3.27427C6.84658 3.86214 6.22469 4.69274 5.89308 5.65052C5.56147 6.60829 5.53664 7.64561 5.82203 8.61815C6.10742 9.5907 6.68884 10.4501 7.48541 11.0768C6.12047 11.6237 4.92952 12.5307 4.03951 13.7011C3.1495 14.8716 2.5938 16.2616 2.43166 17.7231C2.41992 17.8298 2.42932 17.9377 2.4593 18.0408C2.48929 18.1439 2.53929 18.24 2.60644 18.3238C2.74205 18.4929 2.9393 18.6012 3.15478 18.6249C3.37027 18.6487 3.58635 18.5858 3.75549 18.4502C3.92462 18.3146 4.03296 18.1173 4.05666 17.9018C4.23507 16.3135 4.9924 14.8467 6.18396 13.7815C7.37552 12.7163 8.91776 12.1274 10.516 12.1274C12.1143 12.1274 13.6565 12.7163 14.8481 13.7815C16.0397 14.8467 16.797 16.3135 16.9754 17.9018C16.9975 18.1015 17.0928 18.2859 17.2428 18.4194C17.3929 18.5529 17.587 18.6262 17.7879 18.6249H17.8773C18.0903 18.6004 18.2849 18.4927 18.4189 18.3253C18.5528 18.1579 18.6151 17.9444 18.5923 17.7312C18.4294 16.2656 17.8707 14.872 16.9761 13.6997C16.0815 12.5275 14.8848 11.6208 13.5142 11.0768ZM10.4998 10.4999C9.857 10.4999 9.22864 10.3093 8.69418 9.95222C8.15972 9.59511 7.74316 9.08753 7.49718 8.49367C7.25119 7.89981 7.18683 7.24634 7.31223 6.61591C7.43763 5.98547 7.74717 5.40637 8.20169 4.95185C8.65621 4.49733 9.2353 4.1878 9.86574 4.0624C10.4962 3.93699 11.1496 4.00136 11.7435 4.24734C12.3374 4.49332 12.8449 4.90989 13.2021 5.44435C13.5592 5.9788 13.7498 6.60716 13.7498 7.24995C13.7498 8.1119 13.4074 8.93855 12.7979 9.54805C12.1884 10.1575 11.3617 10.4999 10.4998 10.4999Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 p-1.5">
                    <p className="text-sm text-gray-700 font-semibold">Emma Smith</p>
                    <p className="text-xs text-gray-500 font-medium">14 Jun 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-5">
        <div className="flex flex-wrap -m-6 xl:-m-16">
          <div className="w-full md:w-1/2 lg:w-auto p-6 xl:p-16">
            <div className="max-w-xl mx-auto">
              <img src="https://shuffle.dev/placeholder/pictures/1280x720.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
            </div>
          </div>
          <div className="flex-1 md:flex-1 p-6 xl:p-16">
            <div className="flex flex-col justify-between h-full">
              <div className="w-auto mb-9">
                <div className="md:max-w-md">
                  <div className="relative inline-block mb-5">
                    <span className="relative z-10 px-3 py-0.5 text-sm font-bold text-white border border-gray-900">Category</span>
                    <span className="absolute top-1 left-1 w-full h-full bg-lynch-600" />
                  </div>
                  <h5 className="font-heading mb-4 text-2xl font-semibold"><a href="#">Top UI/UX Trends for 2023</a></h5>
                  <p className="text-lg text-gray-500 font-medium">Designing for the modern user.</p>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center -m-1.5">
                  <div className="w-auto p-1.5">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5142 11.0768C14.3107 10.4501 14.8922 9.5907 15.1775 8.61815C15.4629 7.64561 15.4381 6.60829 15.1065 5.65052C14.7749 4.69274 14.153 3.86214 13.3273 3.27427C12.5017 2.6864 11.5133 2.37048 10.4998 2.37048C9.48623 2.37048 8.49788 2.6864 7.67223 3.27427C6.84658 3.86214 6.22469 4.69274 5.89308 5.65052C5.56147 6.60829 5.53664 7.64561 5.82203 8.61815C6.10742 9.5907 6.68884 10.4501 7.48541 11.0768C6.12047 11.6237 4.92952 12.5307 4.03951 13.7011C3.1495 14.8716 2.5938 16.2616 2.43166 17.7231C2.41992 17.8298 2.42932 17.9377 2.4593 18.0408C2.48929 18.1439 2.53929 18.24 2.60644 18.3238C2.74205 18.4929 2.9393 18.6012 3.15478 18.6249C3.37027 18.6487 3.58635 18.5858 3.75549 18.4502C3.92462 18.3146 4.03296 18.1173 4.05666 17.9018C4.23507 16.3135 4.9924 14.8467 6.18396 13.7815C7.37552 12.7163 8.91776 12.1274 10.516 12.1274C12.1143 12.1274 13.6565 12.7163 14.8481 13.7815C16.0397 14.8467 16.797 16.3135 16.9754 17.9018C16.9975 18.1015 17.0928 18.2859 17.2428 18.4194C17.3929 18.5529 17.587 18.6262 17.7879 18.6249H17.8773C18.0903 18.6004 18.2849 18.4927 18.4189 18.3253C18.5528 18.1579 18.6151 17.9444 18.5923 17.7312C18.4294 16.2656 17.8707 14.872 16.9761 13.6997C16.0815 12.5275 14.8848 11.6208 13.5142 11.0768ZM10.4998 10.4999C9.857 10.4999 9.22864 10.3093 8.69418 9.95222C8.15972 9.59511 7.74316 9.08753 7.49718 8.49367C7.25119 7.89981 7.18683 7.24634 7.31223 6.61591C7.43763 5.98547 7.74717 5.40637 8.20169 4.95185C8.65621 4.49733 9.2353 4.1878 9.86574 4.0624C10.4962 3.93699 11.1496 4.00136 11.7435 4.24734C12.3374 4.49332 12.8449 4.90989 13.2021 5.44435C13.5592 5.9788 13.7498 6.60716 13.7498 7.24995C13.7498 8.1119 13.4074 8.93855 12.7979 9.54805C12.1884 10.1575 11.3617 10.4999 10.4998 10.4999Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 p-1.5">
                    <p className="text-sm text-gray-700 font-semibold">Emma Smith</p>
                    <p className="text-xs text-gray-500 font-medium">14 Jun 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-5">
        <div className="flex flex-wrap -m-6 xl:-m-16">
          <div className="w-full md:w-1/2 lg:w-auto p-6 xl:p-16">
            <div className="max-w-xl mx-auto">
              <img src="https://shuffle.dev/placeholder/pictures/1280x720.svg?primary=E2E8F0" alt className="w-full h-full object-cover border-2 border-gray-900 rounded-3xl shadow-retro-img" />
            </div>
          </div>
          <div className="flex-1 md:flex-1 p-6 xl:p-16">
            <div className="flex flex-col justify-between h-full">
              <div className="w-auto mb-9">
                <div className="md:max-w-md">
                  <div className="relative inline-block mb-5">
                    <span className="relative z-10 px-3 py-0.5 text-sm font-bold text-white border border-gray-900">Category</span>
                    <span className="absolute top-1 left-1 w-full h-full bg-lynch-600" />
                  </div>
                  <h5 className="font-heading mb-4 text-2xl font-semibold"><a href="#">How AR and VR are Transforming Digital Experiences</a></h5>
                  <p className="text-lg text-gray-500 font-medium">Immersive experiences for the digital age.</p>
                </div>
              </div>
              <div className="w-auto">
                <div className="flex flex-wrap items-center -m-1.5">
                  <div className="w-auto p-1.5">
                    <div className="relative inline-flex">
                      <div className="relative z-10 flex items-center justify-center w-12 h-12 text-lynch-600 bg-primary-transparent border border-gray-500">
                        <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M13.5142 11.0768C14.3107 10.4501 14.8922 9.5907 15.1775 8.61815C15.4629 7.64561 15.4381 6.60829 15.1065 5.65052C14.7749 4.69274 14.153 3.86214 13.3273 3.27427C12.5017 2.6864 11.5133 2.37048 10.4998 2.37048C9.48623 2.37048 8.49788 2.6864 7.67223 3.27427C6.84658 3.86214 6.22469 4.69274 5.89308 5.65052C5.56147 6.60829 5.53664 7.64561 5.82203 8.61815C6.10742 9.5907 6.68884 10.4501 7.48541 11.0768C6.12047 11.6237 4.92952 12.5307 4.03951 13.7011C3.1495 14.8716 2.5938 16.2616 2.43166 17.7231C2.41992 17.8298 2.42932 17.9377 2.4593 18.0408C2.48929 18.1439 2.53929 18.24 2.60644 18.3238C2.74205 18.4929 2.9393 18.6012 3.15478 18.6249C3.37027 18.6487 3.58635 18.5858 3.75549 18.4502C3.92462 18.3146 4.03296 18.1173 4.05666 17.9018C4.23507 16.3135 4.9924 14.8467 6.18396 13.7815C7.37552 12.7163 8.91776 12.1274 10.516 12.1274C12.1143 12.1274 13.6565 12.7163 14.8481 13.7815C16.0397 14.8467 16.797 16.3135 16.9754 17.9018C16.9975 18.1015 17.0928 18.2859 17.2428 18.4194C17.3929 18.5529 17.587 18.6262 17.7879 18.6249H17.8773C18.0903 18.6004 18.2849 18.4927 18.4189 18.3253C18.5528 18.1579 18.6151 17.9444 18.5923 17.7312C18.4294 16.2656 17.8707 14.872 16.9761 13.6997C16.0815 12.5275 14.8848 11.6208 13.5142 11.0768ZM10.4998 10.4999C9.857 10.4999 9.22864 10.3093 8.69418 9.95222C8.15972 9.59511 7.74316 9.08753 7.49718 8.49367C7.25119 7.89981 7.18683 7.24634 7.31223 6.61591C7.43763 5.98547 7.74717 5.40637 8.20169 4.95185C8.65621 4.49733 9.2353 4.1878 9.86574 4.0624C10.4962 3.93699 11.1496 4.00136 11.7435 4.24734C12.3374 4.49332 12.8449 4.90989 13.2021 5.44435C13.5592 5.9788 13.7498 6.60716 13.7498 7.24995C13.7498 8.1119 13.4074 8.93855 12.7979 9.54805C12.1884 10.1575 11.3617 10.4999 10.4998 10.4999Z" fill="currentColor" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 p-1.5">
                    <p className="text-sm text-gray-700 font-semibold">Emma Smith</p>
                    <p className="text-xs text-gray-500 font-medium">14 Jun 2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


            </>
        </React.Fragment>
    );
}

